$loader-background-opacity: 0.90;

$loader-icon-color : #61dafb;
$loader-icon-opacity : 1;

.Loader {

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: center;
    align-content: center;
    justify-content: center;

    box-sizing: border-box;
    min-width: 0;
    max-width: 100%;
    min-height: 0;
    max-height: 100%;
    font-weight: bold;
    height: 100%;
    width: 100%;

    opacity: $loader-background-opacity;

    .Loader-icon-container {

        & > svg {
            fill: $loader-icon-color;
            opacity: $loader-icon-opacity;
        }

        @keyframes spin {
            from {transform:rotate(0deg);}
            to {transform:rotate(360deg);}
        }

    }

}

