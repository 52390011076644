
$dark-text-color                            : #ffffff;
$dark-link-color                            : #61dafb;
$dark-button-text-color                     : #282c34;
$dark-background-color                      : #282c34;
$dark-alternative-text-color                : #61dafb;
$dark-alternative-background-color          : #1B1E24;

$dark-app-text-color                        : $dark-text-color;
$dark-app-link-text-color                   : $dark-link-color;
$dark-app-background-color                  : $dark-background-color;
$dark-app-header-text-color                 : $dark-text-color;
$dark-app-header-background-color           : $dark-alternative-background-color;
$dark-app-header-link-text-color            : $dark-alternative-text-color;
$dark-app-header-title-color                : $dark-alternative-text-color;
$dark-app-header-title-link-color           : $dark-alternative-text-color;
$dark-app-header-title-icon-color           : $dark-alternative-text-color;
$dark-app-controls-text-color               : $dark-text-color;
$dark-app-controls-background-color         : $dark-background-color;
$dark-app-controls-button-icon-color        : $dark-alternative-text-color;
$dark-app-controls-button-text-color        : $dark-button-text-color;
$dark-app-controls-button-background-color  : $dark-alternative-text-color;
$dark-app-content-text-color                : $dark-text-color;
$dark-app-content-background-color          : $dark-background-color;


$light-text-color                            : #272B33;
$light-link-color                            : #658DD6;
$light-button-text-color                     : #272B33;
$light-background-color                      : #EDF0E9;
$light-alternative-text-color                : #B7F3FE;
$light-alternative-background-color          : #536FB0;

$light-app-text-color                        : $light-text-color;
$light-app-link-text-color                   : $light-link-color;
$light-app-background-color                  : $light-background-color;
$light-app-header-text-color                 : $light-alternative-text-color;
$light-app-header-background-color           : $light-alternative-background-color;
$light-app-header-link-text-color            : $light-alternative-text-color;
$light-app-header-title-color                : $light-alternative-text-color;
$light-app-header-title-link-color           : $light-alternative-text-color;
$light-app-header-title-icon-color           : $light-alternative-text-color;
$light-app-controls-text-color               : $light-text-color;
$light-app-controls-background-color         : $light-background-color;
$light-app-controls-button-text-color        : $light-button-text-color;
$light-app-controls-button-background-color  : $light-alternative-text-color;
$light-app-controls-button-icon-color        : $light-alternative-text-color;
$light-app-content-text-color                : $light-text-color;
$light-app-content-background-color          : $light-background-color;


.App {

  box-sizing : border-box;
  padding    : 0;
  margin     : 0;
  height     : 100%;

  text-align: center;

  display           : flex;
  flex-direction    : column;
  align-items       : stretch;
  justify-content   : stretch;

  a {
    text-decoration: none;
  }

  & > .App-header {

    border-bottom: 2px solid #222222;

    box-sizing : border-box;
    padding    : 1em 0 1em 0;
    margin     : 0;

    min-height : 0;
    max-height : 100%;

    display         : flex;
    flex-direction  : row;
    align-items     : center;
    justify-items   : center;
    align-content   : center;
    justify-content : center;

    & > .App-header-title {

      box-sizing: border-box;
      margin: 0;
      padding: 0;

      display         : flex;
      flex-direction  : row;
      align-items     : center;
      justify-items   : center;
      align-content   : center;
      justify-content : center;

      & > .App-header-title-link {

        box-sizing: border-box;
        margin: 0 1em 0 1em;
        padding: 0;

        display         : flex;
        flex-direction  : row;
        align-items     : center;
        justify-items   : center;
        align-content   : center;
        justify-content : center;

        & > .App-header-title-icon {
          width: auto;
          height: 1.5em;
          box-sizing: border-box;
          margin: 0;
          padding: 0;
        }

      }

    }

    & > .App-chat-controls {

      flex-grow       : 1;

      margin          : 8px;
      padding         : 0;
      box-sizing      : border-box;

      display         : flex;
      flex-direction  : row;
      align-items     : center;
      justify-items   : center;
      align-content   : center;
      justify-content : center;

      & > .chat-button {

        box-sizing: border-box;
        border: 0;
        font-weight: bold;
        text-transform: uppercase;
        padding          : 2px 1em;
        margin: 0 1em;

        display         : flex;
        flex-direction  : row;
        align-items     : center;
        justify-items   : center;
        align-content   : center;
        justify-content : center;

        & > svg {
          box-sizing : border-box;
          height     : 2em;
          width      : auto;
          padding    : 0;
          margin     : 0 0.5em 0 0;
          border     : 0;
        }

      }

      & > .mute-button {

        box-sizing       : border-box;
        border           : 0;
        font-weight      : bold;
        text-transform   : uppercase;
        padding          : 2px 1em;
        margin           : 0;

        display         : flex;
        flex-direction  : row;
        align-items     : center;
        justify-items   : center;
        align-content   : center;
        justify-content : center;

        & > svg {
          box-sizing : border-box;
          height     : 2em;
          width      : auto;
          padding    : 0;
          margin     : 0 0.5em 0 0;
          border     : 0;
        }

      }

    }

    & > .App-header-options {

      flex-shrink     : 0;

      margin          : 8px;
      padding         : 0;
      box-sizing      : border-box;

      display         : flex;
      flex-direction  : row;
      align-items     : center;
      justify-items   : center;
      align-content   : center;
      justify-content : center;

      & > .App-header-options-button {
        box-sizing: border-box;
        border: 0;
        font-weight: bold;
        text-transform: uppercase;
        padding: 0;
        margin: 0;
      }

    }

  }

  & > .App-content {

    flex-grow: 1;

    overflow-y: auto;

    box-sizing : border-box;
    padding    : 0;
    margin     : 0;

    min-height : 0;
    max-height : 100%;

    display         : flex;
    flex-direction  : column;
    align-items     : flex-start;
    justify-items   : center;
    align-content   : center;
    justify-content : flex-start;

    & > .App-content-item {

      font-size: 24px;
      padding: 1em;

    }

    .VideoPlayer {

      margin          : 0;
      display         : flex;
      flex-direction  : column;
      align-items     : center;
      justify-items   : center;
      align-content   : center;
      justify-content : flex-start;

      & > .App-content-video {

        max-width: 100vw;
        max-height: 90vh;
        width: 100%;
        height: auto;

      }

    }

    & > .App-content-hog {
      margin-top: 32px;
      border: 0;
    }

    &.App-content-with-text {
      margin-top: 2em;
    }

    & > .App-chat-disabled {
      display : none;
    }

  }

  &.LiveView-App {

    & > .App-content {

      flex-direction: row;
      align-items     : stretch;

      & > .App-content-main {

        flex-grow: 1;
        margin: 0;
        border: 0;

        display         : flex;
        flex-direction  : column;
        align-items     : center;
        justify-items   : center;
        align-content   : center;
        justify-content : flex-start;

      }

      & > .App-chat {

        flex-shrink: 0;
        min-width: 34em;

      }

      &.App-content-chat-enabled {

        & > .App-content-main {
          border-right: 2px solid #222222;
        }

      }

    }

  }

  &.App-List-View {

    & > .App-content {

      align-items: center;

    }

  }

  &.Dark-App {

    color             : $dark-app-text-color;
    background-color  : $dark-app-background-color;

    a {
      color: $dark-app-link-text-color;
    }

    & > .App-header {

      color             : $dark-app-header-text-color;
      background-color  : $dark-app-header-background-color;

      a {
        color: $light-app-header-link-text-color;
      }

      & > .App-header-title {

        color: $dark-app-header-title-color;

        & > .App-header-title-link {

          color: $dark-app-header-title-link-color;

          & > .App-header-title-icon {
            fill: $dark-app-header-title-icon-color;
          }

        }

      }

      & > .App-chat-controls {

        color             : $dark-app-controls-text-color;

        & > .chat-button {
          color            : $dark-app-controls-button-text-color;
          background-color : $dark-app-controls-button-background-color;
        }

        & > .mute-button {
          color            : $dark-app-controls-button-text-color;
          background-color : $dark-app-controls-button-background-color;
        }

      }

      & > .App-header-options {

        & > .App-header-options-button {

          & > svg {
            fill             : $dark-app-controls-button-icon-color;
          }
        }

      }

    }

    & > .App-content {
      color             : $dark-app-content-text-color;
      background-color  : $dark-app-content-background-color;
    }

    & > .App-footer {

      & > .App-content-logos {

        a {

          & > svg {
            fill: #ffffff;
          }

        }

      }

    }

  }

  &.Light-App {

    color             : $light-app-text-color;
    background-color  : $light-app-background-color;

    a {
      color: $light-app-link-text-color;
    }

    & > .App-header {

      color             : $light-app-header-text-color;
      background-color  : $light-app-header-background-color;

      a {
        color: $light-app-header-link-text-color;
      }

      & > .App-header-title {

        color: $light-app-header-title-color;

        & > .App-header-title-link {

          color: $light-app-header-title-link-color;

          & > .App-header-title-icon {
            fill: $light-app-header-title-icon-color;
          }

        }

      }

      & > .App-chat-controls {

        color             : $light-app-controls-text-color;

        & > .chat-button {
          color            : $light-app-controls-button-text-color;
          background-color : $light-app-controls-button-background-color;
        }

        & > .mute-button {
          color            : $light-app-controls-button-text-color;
          background-color : $light-app-controls-button-background-color;
        }

      }

      & > .App-header-options {

        & > .App-header-options-button {

          & > svg {
            fill             : $light-app-controls-button-icon-color;
          }
        }

      }

    }

    & > .App-content {
      color             : $light-app-content-text-color;
      background-color  : $light-app-content-background-color;
    }

    & > .App-footer {

      & > .App-content-logos {

        a {

          color: #222222;

          & > svg {
            fill: #222222;
          }

        }

      }

      & > .App-content-siteinfo {

        & > a {

          color: #222222;

          & > svg {
            fill: #222222;
          }

        }

      }

    }

  }

}
