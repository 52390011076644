
.App-footer {

  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;

  &.App-footer-disabled {
    display : none;
  }

  & > .App-content-siteinfo {

    margin: 0 2em 8px 0;
    padding: 0;
    box-sizing: border-box;

    & > a {

      margin: 0;
      padding: 8px;
      box-sizing: border-box;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-items: center;
      align-content: center;
      justify-content: center;

      line-height: 1.5em;

      & > .App-content-siteinfo-text {
        padding: 8px;
      }

      & > svg {
        width: 8em;
        margin: 0;
        padding: 0;
        box-sizing: border-box;
      }

      & > img {
        width: 6em;
        height: auto;
        margin: 0 0 0 0;
        padding: 0;
        box-sizing: border-box;
      }

    }

  }

  & > .App-content-hog-iframe {
    flex-grow: 1;
    box-sizing: border-box;
    margin: 0 8px 8px 8px;
    width: 48%;
    max-width: 1280px;
  }

  & > .App-content-logos {

    overflow         : hidden;
    margin           : 0 2em 8px 2em;
    padding          : 0;
    box-sizing       : border-box;
    display          : flex;
    flex-direction   : column;
    align-items      : center;
    justify-items    : center;
    align-content    : center;
    justify-content  : space-evenly;

    & > .title-text {
      flex-grow        : 1;
      margin           : 0.3em;
      padding          : 0;
      box-sizing       : border-box;
      text-transform   : lowercase;
      font-variant     : small-caps;
    }

    & > .split-text {
      flex-grow        : 1;
      margin           : 0.2em 0.2em 0.4em 0.2em;
      padding          : 0;
      box-sizing       : border-box;
      font-size        : 0.75rem;
      text-transform   : lowercase;
      font-variant     : small-caps;
    }

    & > .logo-container {

      margin           : 0.2em;
      flex-grow        : 1;
      padding          : 0;
      box-sizing       : border-box;
      display          : flex;
      flex-direction   : column;
      align-items      : center;
      justify-items    : center;
      align-content    : center;
      justify-content  : center;

      & > .logo-link {

        flex-grow        : 1;
        margin           : 0;
        padding          : 0;
        box-sizing       : border-box;
        display          : flex;
        flex-direction   : column;
        align-items      : center;
        justify-items    : center;
        align-content    : center;
        justify-content  : center;

        & > svg {
          width: auto;
          height: 1.75em;
          margin: 0;
          padding: 0;
          box-sizing: border-box;
        }

        & > .logo-image {
          width: auto;
          height: 1.75em;
          margin: 0;
          padding: 0;
          border: 0;
          outline: 0;
          box-sizing: border-box;
        }

      }

      &.palvelinkauppa-logo {
        margin: 0;
        padding: 0;
        flex-shrink      : 0;
        display          : flex;
        flex-direction   : column;
        align-items      : center;
        justify-items    : center;
        align-content    : center;
        justify-content  : center;
        box-sizing       : border-box;

        & > .logo-link {
          margin: 0;
          padding          : 1px;
          border-radius    : 4px;
          background-color : #fafafa;
          box-sizing       : border-box;
          display          : flex;
          flex-direction   : column;
          align-items      : center;
          justify-items    : center;
          align-content    : center;
          justify-content  : center;
        }

      }

    }

    @media only screen and (max-width: 1024px) {

      margin           : 0;
      flex-direction   : row;
      flex-wrap        : wrap;

      & > .title-text {
        margin: 4px;
      }

      & > .split-text {
        margin: 4px;
      }

      & > .logo-container {
        margin: 4px;
      }

    }

  }

}
