.App-header {

  & > .App-header-options {

    margin           : 0;
    padding          : 0;
    box-sizing       : border-box;

    display          : flex;
    flex-direction   : row;
    align-items      : center;
    justify-items    : stretch;
    align-content    : stretch;
    justify-content  : stretch;

    & > .App-header-options-button {

      background-color : transparent;
      border           : 0;
      margin           : 0;
      padding          : 1.5em;
      box-sizing       : border-box;
      cursor           :  pointer;

      & > svg {
        width: auto;
        height: 2em;
      }

    }

  }

}
