.VideoPlayer {

  box-sizing: border-box;
  padding: 0;
  margin: 0;

  &.VideoPlayer-Loading {

    & > .App-content-video {
      display:none;
    }

  }

}
